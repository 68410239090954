import request from "@/api/service";

const api = {
   toRefund: '/order/order-refund-apply/toRefund',
   refund: '/order/order-refund-apply/submitRefund',
   refundInfo: '/order/order-refund-apply/getRefundInfo'
};

export function toRefund(params) {
   return request({
      url: api.toRefund,
      method: 'POST',
      data: params
   })
}

export function submitRefund(params) {
   return request({
      url: api.refund,
      method: 'POST',
      data: params
   })
}

export function getRefundInfo(params) {
   return request({
      url: api.refundInfo,
      method: 'POST',
      data: params
   })
}
