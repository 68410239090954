<template>
   <div class="cont companies">
     <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>{{$route.query.type === 'group' ? '集团管理' : '业务中台'}}</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item v-if="$route.query.type !== 'group'">订单列表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
     <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <!-- <el-row class="search-item">
                  <label>订单搜索：</label>
                  <el-select class="width-220" v-model="searchQ" clearable>
                     <el-option v-for="item in searchOpt" :key="item.key" :label="item.label" :value="item.value" ></el-option>
                  </el-select>
               </el-row> -->
               <el-row class="search-item">
                <label>订单编号：</label>
                  <el-input v-model="orderNo" :placeholder="'请输入'+ selectText"  class="width-220" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>订单来源：</label>
                  <el-select class="width-220" v-model="orderSourceQ" clearable>
                     <el-option
                          v-show="index !== 'GIFT'"
                          v-for="(item,index) in dictData['order-source']"
                              :key="index"
                              :label="item"
                              :value="index"></el-option>
                  </el-select>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>订单类型：</label>
                  <el-select class="width-220" v-model="orderType" clearable>
                     <el-option v-for="(item,index) in dictData['order-type']" :key="index" :label="item" :value="index"></el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>订单时间：</label>
                  <date-packer :datePacker="dateRange" @setDatePacker="getDatePacker"></date-packer>
               </el-row>
              <HotelSelect :storeIdQ="storeId" v-if="selectshow" @setstoreId="getDate"> </HotelSelect>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 表格 -->
         <el-row class="table-box">
            <div class="table-head-btn">
               <el-button-group class="m-right-10">
                  <el-button :class="orderStatusQ === '' ? 'bg-gradient' : ''" @click="changeStatus('')" ><span>全部</span></el-button>
                  <el-button :class="orderStatusQ === 'PENDING_PAYMENT' ? 'bg-gradient' : ''" @click="changeStatus('PENDING_PAYMENT')" ><span>待付款</span></el-button>
                  <el-button :class="orderStatusQ === 'TO_BE_SHIPPED' ? 'bg-gradient' : ''" @click="changeStatus('TO_BE_SHIPPED')" ><span>待发货</span></el-button>
                  <el-button :class="orderStatusQ === 'PENDING_RECEIPT' ? 'bg-gradient' : ''" @click="changeStatus('PENDING_RECEIPT')" ><span>待收货</span></el-button>
                  <el-dropdown @command="handleCommand">
                     <el-button :type="orderStatusQ === 'TO_BE_REVIEWED' ||
                        orderStatusQ === 'OFF_THE_SHELF' ||
                        orderStatusQ === 'SUSPEND' ? 'primary' : ''">
                        {{ orderStatusQ | filterStatusQ }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                     </el-button>
                     <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="SUCCESSFUL_TRADE">已完成</el-dropdown-item>
                        <el-dropdown-item command="CLOSED_TRANSACTION">已关闭</el-dropdown-item>
                        <el-dropdown-item command="CANCEL">已取消</el-dropdown-item>
                     </el-dropdown-menu>
                  </el-dropdown>
               </el-button-group>
            </div>
            <el-table ref="mainTable" v-loading="tableLoad" :data="tableData" :stripe="true" border fit>
               <el-table-column prop="number" label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column label="订单编号" min-width="150" prop="orderNo"></el-table-column>
               <el-table-column label="订单类型">
                  <template slot-scope="scope">
                     <p> {{scope.row.orderType | filterOrderType(that)}} </p>
                  </template>
               </el-table-column>
               <el-table-column label="收货人" prop="fullName">
                  <template slot-scope="scope">
                     {{scope.row.fullName || scope.row.member ? scope.row.member.nickName : "未获取信息"}}
                  </template>
               </el-table-column>
               <el-table-column label="联系方式" prop="tel">
                  <template slot-scope="scope">
                     {{scope.row.tel || scope.row.member ? scope.row.member.mobile : "未获取信息"}}
                  </template>
               </el-table-column>
               <el-table-column label="实收金额">
                  <template slot-scope="scope">
                     ￥{{scope.row.actualAmount}}
                  </template>
               </el-table-column>
               <el-table-column label="下单时间" prop="createTime" width="160"></el-table-column>
               <el-table-column label="订单来源" prop="orderSource">
                  <template slot-scope="scope">
                     {{scope.row.orderSource | filterOrderSource(that)}}
                  </template>
               </el-table-column>
               <el-table-column label="订单状态" prop="orderStatus">
                  <template slot-scope="scope">
                     {{scope.row.orderStatus | filterOrderStatus(that)}}
                  </template>
               </el-table-column>
              <el-table-column prop="hotelName" label="所属酒店" min-width="150"></el-table-column>
               <el-table-column :label="$t('msg.operating')" fixed="right" min-width="140">
                  <template slot-scope="scope">
                     <el-button  type="text" @click="handleDetail(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
                     </el-button>
                    <el-button v-if="(scope.row.orderStatus === 'HAVE_PAID' ||
                                      scope.row.orderStatus === 'CHECKIN') &&
                                      scope.row.orderType !== 'DEPOSIT_CARD'"
                               @click="handleRefund(scope.row.id)" type="text">退款</el-button>
                    <el-button v-if="scope.row.orderStatus === 'REFUNDED'" @click="lookRefundDetail(scope.row.id)" type="text">查看退款信息</el-button>
                    <el-button v-if="scope.row.orderType === 'GOODS'" type="text" @click="handleDeliver(scope.row.id)">
                      <el-link type="primary">确认发货</el-link>
                    </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-row>
     <el-dialog title="退款" :visible.sync="refundVisible" width="550px">
       <el-form class="refund-form" ref="refundForm" :model="refundForm" :rules="refundRules">
         <el-form-item label="订单编号">{{ refundForm.orderId }}</el-form-item>
         <el-form-item label="下单电话">{{ refundForm.mobile }}</el-form-item>
         <el-form-item label="下单时间">{{ refundForm.createTime }}</el-form-item>
         <el-form-item label="实付金额">{{ refundForm.actualAmount }}</el-form-item>
         <el-form-item label="储值卡抵扣金额">{{ refundForm.depositcardAmount }}</el-form-item>
         <el-form-item label="选择退款">
           <el-radio @change="refundForm.amount = refundForm.transactionAmount" v-model="refundForm.refundType" label="FULL">全部</el-radio>
           <el-radio v-model="refundForm.refundType" label="PART">部分退款</el-radio>
         </el-form-item>
         <el-form-item class="m-bottom-20" label="退款金额" prop="amount">
          <el-input class="width-280" v-model="refundForm.amount" placeholder="退款金额" :disabled="refundForm.refundType === 'FULL'"></el-input>
         </el-form-item>
         <el-form-item label="备注">
           <el-input class="width-280" v-model="refundForm.reason" type="textarea" placeholder="备注"></el-input>
         </el-form-item>
       </el-form>
       <span slot="footer">
         <el-button @click="refundVisible = false">取消</el-button>
         <el-button @click="confirmRefund" type="primary" :loading="refundLoad">
           {{ refundLoad ? '退款中...' : '确定退款' }}
         </el-button>
       </span>
     </el-dialog>
     <el-dialog title="查看退款信息" :visible.sync="detailVisible" width="550px">
       <el-descriptions title="" :column="2">
         <el-descriptions-item label="订单编号">{{ refundDetails.orderId }}</el-descriptions-item>
         <el-descriptions-item label="下单电话">{{ refundDetails.mobile }}</el-descriptions-item>
         <el-descriptions-item label="下单时间">{{ refundDetails.orderTime }}</el-descriptions-item>
         <el-descriptions-item label="实付金额">{{ refundDetails.actualAmount }}</el-descriptions-item>
         <el-descriptions-item label="储值卡抵扣金额">{{ refundDetails.depositcardAmount }}</el-descriptions-item>
         <el-descriptions-item label="退款类型">{{ refundDetails.refundType | filterRefundType }}</el-descriptions-item>
         <el-descriptions-item label="退款金额">{{ refundDetails.refundAmount }}</el-descriptions-item>
         <el-descriptions-item label="备注">{{ refundDetails.reason }}</el-descriptions-item>
       </el-descriptions>
       <span slot="footer">
         <el-button @click="detailVisible = false">关闭</el-button>
       </span>
     </el-dialog>
   </div>
</template>
<script>
import { urlObj } from '@/api/interface';
import { getDict } from '@/common/js/common';
import { mapState } from 'vuex';
import { toRefund, submitRefund, getRefundInfo } from '@/api/business/order/order';
import { verificationRule } from '@/common/js/common'
export default {
  data(){
    const validAmount = (rule, value, callback) => {
      if (verificationRule.decimal(value)) callback(new Error('金额不是数字'))
      else if (value > this.refundForm.transactionAmount) callback(new Error('退款金额已超出'))
      else callback()
    }
    return{
      that: this,
      tableData: [],     // 订单数据
      tableLoad: true,   // 表格加载动画
      total: 0,          // 数据总条数
      selectshow:false,
      searchOpt: [
        { label: '订单编号', value: 'orderNo' },
        // { label: '收货人姓名', value: 'fullName' },
        // { label: '收货人手机号', value: 'mobile' },
      ],
      hotellist:[],
      selectText:'',
      page: 1,             // 当前页
      limit: 0,            // 每页条数
      orderSourceQ: '',    // 订单来源
      searchQ: 'orderNo',         // 搜索方式
      dateRange: [],
      storeId:'',
      dateValue: '',       // 订单时间
      hotelId: '',         // 酒店ID
      orderNo:'',          // 订单号
      orderType: '',       // 订单类型
      orderStatusQ: '',    // 订单状态
      refundVisible: false,
      refundForm: {
        orderId: '',
        refundType: 'FULL',
        amount: '',
        mobile: '',
        createTime: '',
        actualAmount: 0,
        depositcardAmount: 0,
        transactionAmount: 0,
        reason: ''
      },
      refundRules: {
        amount: [{ required: true, validator: validAmount }]
      },
      detailVisible: false,
      refundDetails: {
        orderId: '',
        mobile: '',
        refundType: '',
        orderTime: '',
        refundAmount: 0,
        actualAmount: 0,
        depositcardAmount: 0,
        reason: ''
      },
      refundLoad: false
    }
  },
  computed:{
    ...mapState(['hotelInfo','dictData'])
  },
  mounted() {
    getDict(['order-source','order-status','order-type'],true)
    this.hotelId = this.hotelInfo.id
    this.limit = sessionStorage.getItem('pageSize') * 1
    // if (JSON.parse(sessionStorage.getItem('userInfo')).accountType === 'STORE'){
    //   this.selectshow = true
    // }
    if(this.$route.query.type === 'group'){
      this.selectshow = true
    }
    this.getOrders()
  },
  methods: {
    // 获取订单列表
    getOrders(){
      console.log( this.hotelInfo)
      const url = urlObj.getOrder + `?page=${this.page}&limit=${this.limit}`
      /*
      如果选择“客房”取字段orderTypeList = ['HOTEL','E_BOOKING']
      否则取字段orderType=this.orderType
      * */
      const param = {
        hotelId: this.hotelId,
        orderSource: this.orderSourceQ,
        search: this.searchQ,
        orderStatus: this.orderStatusQ,
        orderNo: this.orderNo,
        beginTime:this.dateRange[0],
        endTime:this.dateRange[1],
        orderType: this.orderType,
      }
      if(this.orderType  === 'HOTEL'){
        delete param.orderType
        param.orderTypeList = ['HOTEL','E_BOOKING']
      }
      // if (JSON.parse(sessionStorage.getItem('userInfo')).accountType === 'STORE'){
      if(this.$route.query.type === 'group'){
        param.storeId = this.hotelInfo.storeId
        param.hotelId = this.storeId
      }
      this.$axios.post(url, param, 'json').then(res => {
        if (res.success){
          this.tableLoad = false
          this.total = res.total
          this.tableData = res.records
        }
      })
    },
    getDate(val){   
      this.storeId = val
      // this.hotelId = val
    },
    // 改变优惠券状态
    changeStatus(val){
      this.page = 1
      this.orderStatusQ = val
      this.getOrders()
    },
    // 优惠券其他状态
    handleCommand(val){
      this.orderStatusQ = val
      this.getOrders()
    },
    // 获取查询活动时间
    getDatePacker(val){
      this.dateRange = val
    },
    // 查询订单
    handleQuery(bool){
      if (bool) {
        this.page = 1
        this.getOrders()
      }else {
        this.orderSourceQ = ''
        this.searchQ = 'orderNo'
        this.storeId = ''
        this.orderNo = ''
        this.orderType = ''
        this.dateRange = []
      }
    },
    // 改变显示条数
    changePageNum(num){
      this.limit = num
      this.getOrders()
    },
    // 改变当前页
    handleCurrPage (num) {
      this.page = num
      this.getOrders()
    },
    // 查看详情
    handleDetail(order){
      this.$router.push({ path: '/order_manage/order_detail', query:{ orderNo:order.orderNo, hotelName:order.hotelName }})
    },
    // 处理发货
    handleDeliver(){
      this.$router.push('/order_manage/deliver_goods')
    },
    // 退款
    handleRefund(id){
      this.refundVisible = true
      toRefund({ orderId: id }).then(({ success, records, message }) => {
        if (success) {
          for (const k in this.refundForm) {
            if (k === 'orderId') this.refundForm[k] = records.id
            else if (k === 'amount') this.refundForm[k] = records.transactionAmount
            else if (k in records) this.refundForm[k] = records[k]
          }
        } else this.$message.error(message)
      })
    },
    // 确认退款
    confirmRefund() {
      this.$confirm(`确定退款${ this.refundForm.amount }元？`, '提示', {
        confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
      }).then(() => {
        this.$refs.refundForm.validate(valid => {
          if (!valid) return;
          this.refundLoad = true
          const params = {
            orderId: this.refundForm.orderId,
            refundType: this.refundForm.refundType,
            amount: String(this.refundForm.amount),
            reason: this.refundForm.reason
          };
          submitRefund(params).then(({ success }) => {
            if (!success) return;
            this.refundVisible = false
            this.getOrders()
            this.$message({ message: '退款成功！', type: 'success' })
          }).finally(() => this.refundLoad = false)
        })
      })
    },
    // 查看退款详情
    lookRefundDetail(id) {
      this.detailVisible = true;
      getRefundInfo({ orderId: id }).then(({ success, records }) => {
        if (!success || records.length < 0) return;
        for (const k in this.refundDetails) {
          k in records[0] && (this.refundDetails[k] = records[0][k])
        }
      })
    }
  },
  watch:{
    hotelInfo:{
      handler(newVal,oldVal){
        if (newVal.id !== oldVal.id) {
          this.hotelId = this.hotelInfo.id
          this.getOrders()
        }
      }
    }
  },
  filters: {
    filterStatusQ(val){
      switch (val){
        case 'TO_BE_REVIEWED':
          return val = '待审核'
        case 'OFF_THE_SHELF':
          return val = '已下架'
        default:
          return val = '其他'
      }
    },
    filterOrderStatus(val,that){
      if (val && that.dictData['order-status']) return that.dictData['order-status'][val] || val
    },
    filterOrderSource(val,that){
      if (val && that.dictData['order-source']) return that.dictData['order-source'][val] || val
    },
    filterOrderType(val,that){
      if(val === 'E_BOOKING') return '客房'
      if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
    },
    filterRefundType(val) {
      const obj = { FULL: '全额退款', PART: '部分退款' };
      return obj[val]
    }
  }
}
</script>

<style lang="scss" scoped>
.cont {
  .el-dropdown{
    border-left: solid 1px #DCDFE6;
  }
  .refund-form{
    padding: 0 30px;
    .el-form-item {
      margin-bottom: 6px;
      ::v-deep .el-form-item__label{ width: 110px }
      ::v-deep .el-form-item__error{ margin-left: 110px }
    }
  }
}

</style>
